import { Subscription } from 'aurelia-event-aggregator';
import { SpecialProductDimensionService } from './../../services/special-product-dimension-service';
import { TransportTypeService } from './../../services/transport-type-service';
import { DialogController, DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { bindable } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { AddressService } from 'services/address-service';
import { ContactService } from 'services/contact-service';
import { DepartmentService } from 'services/department-service';
import { ErrorService } from 'services/error-service';
import { NetDimensionService } from 'services/net-dimension-service';
import { NetService } from 'services/net-service';
import { ServiceService } from 'services/service-service';
import { SiteService } from 'services/site-service';
import { ToastService } from 'services/toast-service';
import { Utility } from '../../utility';
import * as moment from 'moment';
import { ServiceStationService } from 'services/service-station-service';
import { InvoiceableService } from 'models';
import { ServiceInvoiceStatus } from 'models/ServiceInvoiceStatus';
import { NettingService } from 'services/netting-service';

@autoinject
export class ServiceDetailOrderdetails {
  private net: any = null;
  private netdimension: Models.NetDimension;
  private specialProductDimension: Models.SpecialProductDimension;
  private service: any = null;

  // keep a copy of the service to simplify handling invoicing/orderinfo
  private serviceCloned: any = null;

  private customerid: number;
  private departments: Array<Models.Department>;
  private sites: Array<Models.Site>;
  private deliveryDepartments: Array<Models.Department>;
  private deliverySites: Array<Models.Site>;
  private addresses: Array<Models.Address>;
  private contacts: Array<Models.Contact>;
  private contactsBoat: Array<Models.Contact>; // because of a select2-bug
  private originalObject;
  private locked;
  private nextTabIndex: number = null;
  private extendValidity: boolean = false;

  private serviceUpdatedSubscription: Subscription;

  @bindable private serviceId;
  @bindable private netId;

  private unusedTasks = [];
  private orderTasks = [];
  private allTasks = [
    {
      invoiceDate: 'InvoicedWashing',
      invoiceable: 'InvoiceWashing',
      completedDate: 'WashedDate',
      canSetCompleteDate: true,
      label: 'service.tasks.washing',
      orderedDate: 'OrderedWashing',
      serviceStationId: 'WashingServiceStationId',
      serviceStationName: 'WashingServiceStation',
      index: 10,
    },
    {
      invoiceDate: null,
      invoiceable: null,
      completedDate: 'MeasuredDate',
      canSetCompleteDate: true,
      label: 'service.tasks.measuring',
      orderedDate: 'OrderedMeasuring',
      serviceStationId: 'MeasuringServiceStationId',
      serviceStationName: 'MeasuringServiceStation',
      index: 20,
    },
    {
      invoiceDate: 'InvoicedTesting',
      invoiceable: 'InvoiceTesting',
      completedDate: 'TestedDate',
      canSetCompleteDate: true,
      label: 'service.tasks.testing',
      orderedDate: 'OrderedTesting',
      serviceStationId: 'TestingServiceStationId',
      serviceStationName: 'TestingServiceStation',
      index: 30,
    },
    {
      invoiceDate: 'InvoicedRepair',
      invoiceable: 'InvoiceRepair',
      completedDate: 'RepairedDate',
      canSetCompleteDate: true,
      label: 'service.tasks.repairing',
      orderedDate: 'OrderedRepair',
      serviceStationId: 'RepairServiceStationId',
      serviceStationName: 'RepairServiceStation',
      index: 40,
    },
    {
      invoiceDate: 'InvoicedAntifouling',
      invoiceable: 'InvoiceAntifouling',
      completedDate: 'AntifouledDate',
      canSetCompleteDate: true,
      label: 'service.tasks.antifouling',
      orderedDate: 'OrderedAntifouling',
      serviceStationId: 'AntifoulingServiceStationId',
      serviceStationName: 'AntifoulingServiceStation',
      index: 50,
    },
    {
      invoiceDate: 'InvoicedSpaghetti',
      invoiceable: 'InvoiceSpaghetti',
      completedDate: 'SpaghettiDate',
      canSetCompleteDate: false,
      label: 'service.tasks.spaghetti',
      orderedDate: 'OrderedSpaghetti',
      serviceStationId: 'SpaghettiServiceStationId',
      serviceStationName: 'SpaghettiServiceStation',
      index: 51,
    },
    {
      invoiceDate: 'InvoicedDelivery',
      invoiceable: 'InvoiceDelivery',
      completedDate: 'DeliveredDate',
      canSetCompleteDate: false,
      label: 'service.tasks.delivery',
      orderedDate: 'OrderedDelivery',
      serviceStationId: 'DeliveryServiceStationId',
      serviceStationName: 'DeliveryServiceStation',
      index: 60,
    },
  ] as const;

  private taskStatuses: any;

  @bindable private isDialog: boolean = false;

  private serviceStations: Array<Models.ServiceStation>;

  constructor(
    private dialogService: DialogService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private netService: NetService,
    private router: Router,
    private toastService: ToastService,
    private utility: Utility,
    private departmentService: DepartmentService,
    private siteService: SiteService,
    private serviceService: ServiceService,
    private adressService: AddressService,
    private contactService: ContactService,
    private netDimensionService: NetDimensionService,
    private nettingService: NettingService,
    private specialProductDimensionService: SpecialProductDimensionService,
    private transportTypeService: TransportTypeService,
    private serviceStationService: ServiceStationService
  ) {}

  private attached() {
    if (this.serviceId && this.netId) {
      this.activate({ Id: this.serviceId, NetId: this.netId });
    }

    this.serviceUpdatedSubscription = this.eventAggregator.subscribe('serviceUpdated', () => {
      // if service is updated, refresh the service object unless we have pending changes
      this.updateServiceStatusAndDates(this.serviceId);
    });
  }

  private activate(params) {
    if (!this.serviceId && params.Id) {
      this.serviceId = params.Id;
    }

    this.getService(params.Id);
    this.getNet(params.NetId);
    this.getServiceStations();
    void this.getServiceInvoiceStatus(params.Id);

    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.eventAggregator.subscribe('changeTab', (tabIndex) => {
      this.nextTabIndex = tabIndex;
    });
  }

  private deactivate() {
    if (this.serviceUpdatedSubscription) {
      this.serviceUpdatedSubscription.dispose();
    }
  }

  private detached() {
    if (this.serviceUpdatedSubscription) {
      this.serviceUpdatedSubscription.dispose();
    }
  }

  invoiceStatuses: {
    [key in (typeof this.allTasks)[number]['label']]: {
      status: 'notInvoiced' | 'partial' | 'invoiced' | undefined;
      date?: string;
    };
  } = {
    'service.tasks.washing': undefined,
    'service.tasks.testing': undefined,
    'service.tasks.repairing': undefined,
    'service.tasks.antifouling': undefined,
    'service.tasks.spaghetti': undefined,
    'service.tasks.delivery': undefined,
    'service.tasks.measuring': undefined,
  };

  private serviceInvoiceStatus: ServiceInvoiceStatus;

  private async getServiceInvoiceStatus(serviceId: number) {
    try {
      this.serviceInvoiceStatus = await this.serviceService.getServiceInvoiceStaatus(serviceId);
      this.setInvoiceStatuses();
    } catch (error) {
      //
    }
  }

  protected getInvoicedDate(taskLabel: (typeof this.allTasks)[number]['label']) {
    if (!this.serviceInvoiceStatus) return '';

    let date: Date | string;
    switch (taskLabel) {
      case 'service.tasks.washing': {
        date = this.serviceInvoiceStatus.InvoicedWashingAt;
        break;
      }
      case 'service.tasks.repairing': {
        date = this.serviceInvoiceStatus.InvoicedRepairAt;
        break;
      }
      case 'service.tasks.testing': {
        date = this.serviceInvoiceStatus.InvoicedTestingAt;
        break;
      }
      case 'service.tasks.spaghetti': {
        date = this.serviceInvoiceStatus.InvoiceSpaghettiAt;
        break;
      }
      case 'service.tasks.antifouling': {
        date = this.serviceInvoiceStatus.InvoiceImpregnationAt;
        break;
      }
      case 'service.tasks.delivery': {
        date = this.serviceInvoiceStatus.InvoiceDeliveryAt;
        break;
      }
    }
    if (!date) return '';

    return moment.utc(date).format('DD.MM.YY');
  }

  setInvoiceStatuses() {
    if (!this.serviceInvoiceStatus) return undefined;
    const status = this.serviceInvoiceStatus;

    const processes: {
      toInvoice: keyof ServiceInvoiceStatus;
      invoiced: keyof ServiceInvoiceStatus;
      name: (typeof this.allTasks)[number]['label'];
      date?: string;
    }[] = [
      {
        invoiced: 'HasInvoivedWashing',
        toInvoice: 'WashingToInvoice',
        name: 'service.tasks.washing',
        date: this.getInvoicedDate('service.tasks.washing'),
      },
      {
        invoiced: 'HasInvoivedTesting',
        toInvoice: 'TestingToInvoice',
        name: 'service.tasks.testing',
        date: this.getInvoicedDate('service.tasks.testing'),
      },
      {
        invoiced: 'HasInvoivedRepair',
        toInvoice: 'RepairToInvoice',
        name: 'service.tasks.repairing',
        date: this.getInvoicedDate('service.tasks.repairing'),
      },
      {
        invoiced: 'HasInvoivedAntifouling',
        toInvoice: 'AntifoulingToInvoice',
        name: 'service.tasks.antifouling',
        date: this.getInvoicedDate('service.tasks.antifouling'),
      },
      {
        invoiced: 'HasInvoivedSpaghetti',
        toInvoice: 'SpaghettiToInvoice',
        name: 'service.tasks.spaghetti',
        date: this.getInvoicedDate('service.tasks.spaghetti'),
      },
      {
        invoiced: 'HasInvoivedDelivery',
        toInvoice: 'DeliveryToInvoice',
        name: 'service.tasks.delivery',
        date: this.getInvoicedDate('service.tasks.delivery'),
      },
    ];

    for (const process of processes) {
      const task = this.orderTasks.find((x) => x.label === process.name);
      if (task) {
        process.date
          ? (this.orderTasks.find((x) => x.label === process.name).invoicedAtDate = process.date)
          : (this.orderTasks.find((x) => x.label === process.name).invoicedAtDate = undefined);
      }

      this.invoiceStatuses[process.name] = {
        status: undefined,
        date: process.date,
      };

      if (status[process.toInvoice] && status[process.invoiced]) {
        this.invoiceStatuses[process.name] = {
          status: 'partial',
          date: process.date,
        };
      } else if (status[process.toInvoice] && !status[process.invoiced]) {
        this.invoiceStatuses[process.name] = {
          status: 'notInvoiced',
          date: process.date,
        };
      } else if (!status[process.toInvoice] && status[process.invoiced]) {
        this.invoiceStatuses[process.name] = {
          status: 'invoiced',
          date: process.date,
        };
      }
    }
    this.invoiceStatuses = { ...this.invoiceStatuses };
    this.orderTasks = [...this.orderTasks];
  }

  private saveService() {
    const taskServiceStations = this.allTasks.map((it) => it.serviceStationName);
    taskServiceStations.forEach((x) => {
      delete this.service[x];
    });

    this.serviceService
      .put(this.service, this.service.Id)
      .then(() => {
        this.originalObject = null;
        this.toastService.showSuccess('service.orderDetailsSaved');

        this.eventAggregator.publish('serviceUpdated');

        this.getService(this.service.Id);
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getService(id) {
    const tasks: string = this.allTasks.map((it) => it.serviceStationName).join(',');

    this.serviceService
      .get(id + '?$expand=' + tasks)
      .then((res) => {
        this.service = res;
        this.serviceCloned = JSON.parse(JSON.stringify(res));

        this.setCheckboxValuesBasedOnService();
        this.setupTaskArrays();

        this.originalObject = JSON.parse(JSON.stringify(res));
        this.locked = res.Locked ? res.Locked : false;

        if (res.ServiceTypeId == 2) {
          this.extendValidity = true;
        }
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getServiceStations() {
    this.serviceStationService
      .getAllCached()
      .then((res) => {
        this.serviceStations = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private updateServiceStatusAndDates(id) {
    this.serviceService
      .get(id)
      .then((updatedService) => {
        let propsToUpdate = [
          'WashedDate',
          'MeasuredDate',
          'TestedDate',
          'RepairedDate',
          'AntifouledDate',
          'ServiceStatusId',
        ];

        propsToUpdate.forEach((p) => {
          this.service[p] = updatedService[p];
          this.serviceCloned[p] = updatedService[p];
        });

        this.setCheckboxValuesBasedOnService();
        this.setupTaskArrays();

        this.locked = updatedService.Locked ? updatedService.Locked : false;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getNet(id) {
    this.netService
      .getNetInfo(id)
      .then((res) => {
        this.net = res;

        this.customerid = res.CustomerId;
        this.getDimensions(this.net);
        this.getDepartments(this.customerid);
        this.getSites(this.customerid);
        this.getAdresses(this.customerid);
        this.getContacts(this.customerid);
        if (!this.net.NettingTypeId) {
          this.getNettingTypeName(id);
        }
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private async getNettingTypeName(id: number) {
    const netting = await this.nettingService.get(
      '?$filter=NetId eq ' + id + '&$expand=MeshSize,NylonType,ThreadType,Material'
    );
    let nettingTypeName = '';
    if (netting[0]?.Material) {
      nettingTypeName += netting[0].Material.Name + ' ';
    }
    if (netting[0]?.ThreadType) {
      nettingTypeName += netting[0].ThreadType.Name + ' ';
    }
    if (netting[0]?.NylonType) {
      nettingTypeName += netting[0].NylonType.Name + ' ';
    }
    if (netting[0]?.MeshSize) {
      nettingTypeName += netting[0].MeshSize.Name;
    }
    this.net.NettingTypeName = nettingTypeName;
  }

  private getDimensions(net: Models.Net) {
    if (net.NetDimensionId) {
      this.netDimensionService
        .get(net.NetDimensionId + '?$expand=DimensionClass')
        .then((res) => {
          this.netdimension = res;
        })
        .catch((err) => this.errorService.handleError(err));
    } else if (net.SpecialProductDimensionId) {
      this.specialProductDimensionService
        .get(net.SpecialProductDimensionId)
        .then((res) => {
          this.specialProductDimension = res;
        })
        .catch((err) => this.errorService.handleError(err));
    }
  }

  private getDepartments(customerId) {
    this.departmentService
      .getAll('?$filter=CustomerId eq ' + customerId)
      .then((res) => {
        this.departments = res;
        this.deliveryDepartments = JSON.parse(JSON.stringify(res));
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getSites(CustomerId) {
    this.siteService
      .getAll('?$filter=CustomerId eq ' + CustomerId)
      .then((res) => {
        this.sites = res;
        this.deliverySites = JSON.parse(JSON.stringify(res));
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getAdresses(CustomerId) {
    this.adressService
      .getAll('?$filter=CustomerId eq ' + CustomerId)
      .then((res) => {
        const data: Array<any> = res.map((it: any) => {
          it.Formatted = it.Address1 + ', ' + it.Zip + ' ' + it.City + ', ' + it.Country;
          return it;
        });
        this.addresses = data;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getContacts(CustomerId) {
    this.contactService
      .getAll('?$filter=CustomerId eq ' + CustomerId)
      .then((res) => {
        this.contacts = res.filter(
          (x) => x.IsResponsibleForUnloading || (!x.IsResponsibleForOffers && !x.IsResponsibleForUnloading)
        );
        this.contactsBoat = JSON.parse(
          JSON.stringify(
            res.filter(
              (x) => x.IsResponsibleForUnloading || (!x.IsResponsibleForOffers && !x.IsResponsibleForUnloading)
            )
          )
        );
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private delegateContact(event, boat) {
    if (!this.contacts) {
      return;
    }
    const unloadingContact = this.contacts.filter(
      (it: any) => parseInt(it.Id, 10) === parseInt(event.detail.value, 10)
    )[0];

    if (boat === true) {
      this.service.UnloadingBoatContactId = event.detail.value;
      this.service.UnloadingBoatContactPhone = unloadingContact.Phone;
    } else {
      this.service.UnloadingContactId = event.detail.value;
      this.service.UnloadingContactPhone = unloadingContact.Phone;
    }
  }

  setCheckboxValuesBasedOnService() {
    if (!this.serviceCloned) {
      return;
    }

    const valuesToSet = [
      'InvoicedWashing',
      'InvoicedTesting',
      'InvoicedRepair',
      'InvoicedAntifouling',
      'InvoicedSpaghetti',
      'InvoicedDelivery',
    ];
    this.taskStatuses = {};
    valuesToSet.forEach((v) => {
      this.taskStatuses[v] = !!this.serviceCloned[v];
    });
  }

  setupTaskArrays() {
    // set up an array of used an unused ordered items based on the service
    this.unusedTasks = [];
    this.orderTasks = [];

    this.allTasks.forEach((t) => {
      if (!this.serviceCloned[t.orderedDate]) {
        this.unusedTasks.push(t);
      } else {
        this.orderTasks.push(t);
      }
    });

    void this.setInvoiceStatuses();
  }

  addTask(task) {
    if (!this.serviceCloned[task.orderedDate]) {
      this.serviceService
        .patchValue(this.serviceCloned.Id, task.orderedDate, true)
        .then((res) => {
          this.toastService.showSuccess('service.updated');
          this.eventAggregator.publish('dropdownClose', 1);

          this.serviceCloned[task.orderedDate] = true;

          this.setupTaskArrays();
          this.setCheckboxValuesBasedOnService();

          this.serviceCloned[task.orderedDate] = res[task.orderedDate];
          this.service[task.orderedDate] = res[task.orderedDate];
          this.originalObject[task.orderedDate] = res[task.orderedDate];

          this.eventAggregator.publish('serviceUpdated');
          this.eventAggregator.publish('refresh-tabs');
        })
        .catch((err) => this.errorService.handleError(err));
    }
  }

  removeTask(task: any) {
    if (this.serviceCloned[task.orderedDate]) {
      this.serviceService
        .patchValue(this.serviceCloned.Id, task.orderedDate, false)
        .then((res) => {
          this.toastService.showSuccess('service.updated');
          this.eventAggregator.publish('dropdownClose', 1);

          this.serviceCloned[task.orderedDate] = false;

          this.setupTaskArrays();
          this.setCheckboxValuesBasedOnService();

          this.serviceCloned[task.orderedDate] = res[task.orderedDate];
          this.service[task.orderedDate] = res[task.orderedDate];
          this.originalObject[task.orderedDate] = res[task.orderedDate];

          this.eventAggregator.publish('serviceUpdated');
          this.eventAggregator.publish('refresh-tabs');
        })
        .catch((err) => this.errorService.handleError(err));
    }
  }

  setTaskFinished(dateToSet: string) {
    if (!this.serviceCloned) {
      return;
    }

    if (!this.serviceCloned[dateToSet]) {
      this.serviceService
        .patchValue(this.serviceCloned.Id, dateToSet, moment.default().format('YYYY-MM-DD'))
        .then((res) => {
          this.toastService.showSuccess('service.updated');
          this.serviceCloned[dateToSet] = res[dateToSet];
          this.service[dateToSet] = res[dateToSet];
          this.originalObject[dateToSet] = res[dateToSet];

          this.serviceCloned.ServiceStatusId = res.ServiceStatusId;
          this.service.ServiceStatusId = res.ServiceStatusId;
          this.originalObject.ServiceStatusId = res.ServiceStatusId;

          this.getServiceInvoiceStatus(this.serviceId);
          this.eventAggregator.publish('serviceUpdated');
        })
        .catch((err) => this.errorService.handleError(err));
    }
  }

  setTaskInvoiceable(task, value) {
    if (!this.serviceCloned) {
      return;
    }

    if (this.serviceCloned[task] != value) {
      this.serviceService
        .patchValue(this.serviceCloned.Id, task, value)
        .then((res) => {
          this.toastService.showSuccess('service.updated');
          this.serviceCloned[task] = res[task];
          this.service[task] = res[task];
          this.originalObject[task] = res[task];

          this.serviceCloned.ServiceStatusId = res.ServiceStatusId;
          this.service.ServiceStatusId = res.ServiceStatusId;
          this.originalObject.ServiceStatusId = res.ServiceStatusId;
        })
        .catch((err) => this.errorService.handleError(err));
    }
  }

  private setInvoiced(dateToSet: string) {
    if (!this.serviceCloned) {
      return;
    }

    if (
      (!this.taskStatuses[dateToSet] && !!this.serviceCloned[dateToSet]) ||
      (this.taskStatuses[dateToSet] && !this.serviceCloned[dateToSet])
    ) {
      this.serviceService
        .patchValue(
          this.serviceCloned.Id,
          dateToSet,
          this.taskStatuses[dateToSet] ? moment.default().format('YYYY-MM-DD') : null
        )
        .then((res) => {
          this.toastService.showSuccess('service.updated');
          this.service[dateToSet] = res[dateToSet];
          this.serviceCloned[dateToSet] = res[dateToSet];
          this.originalObject[dateToSet] = res[dateToSet];

          this.serviceCloned.ServiceStatusId = res.ServiceStatusId;
          this.service.ServiceStatusId = res.ServiceStatusId;
          this.originalObject.ServiceStatusId = res.ServiceStatusId;

          this.setCheckboxValuesBasedOnService();
        })
        .catch((err) => this.errorService.handleError(err));
    }
  }

  private setAndSaveDateField(dateToSet: string, event) {
    let date = null;
    if (!event.firedBy) {
      // this means date was cleared
    } else {
      date = event.firedBy._d;

      if (
        date &&
        this.serviceCloned[dateToSet] &&
        moment.default(date).format('YYYY-MM-DD') == moment.default(this.serviceCloned[dateToSet]).format('YYYY-MM-DD')
      ) {
        return;
      }
    }

    // autosave when changing date
    this.serviceService
      .patchValue(this.serviceCloned.Id, dateToSet, date ? moment.default(date).format('YYYY-MM-DD') : null)
      .then((res) => {
        this.toastService.showSuccess('service.updated');
        this.serviceCloned[dateToSet] = res[dateToSet];
        this.setCheckboxValuesBasedOnService();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private canDeactivate() {
    if (this.originalObject && !this.utility.areEqual(this.service, this.originalObject, true)) {
      return this.dialogService
        .open({
          viewModel: Prompt,
          model: { header: 'dialog.pleaseConfirmHeader', message: 'dialog.unsavedChangesText' },
        })
        .whenClosed((response) => {
          if (response.wasCancelled) {
            return false;
          } else {
            const result = response.output;
            if (result === 'save') {
              // Save the service and let that function handle the rest of the logic
              this.saveService();
              return false;
            } else {
              this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
              return true;
            }
          }
        });
    } else {
      this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
      return true;
    }
  }

  private editTaskServiceStation(task: any): void {
    task.isEditingServiceStation = true;
  }

  private setTaskServiceStation(task: any, event: any): void {
    this.serviceService
      .patchValue(this.serviceCloned.Id, task.serviceStationId, event.detail.value)
      .then((res) => {
        this.toastService.showSuccess('service.updated');
        this.serviceCloned[task.serviceStationId] = res[task.serviceStationId];
        this.serviceCloned[task.serviceStationName] = this.serviceStations.find((x) => x.Id == event.detail.value);
        task.isEditingServiceStation = false;
      })
      .catch((err) => this.errorService.handleError(err));
  }
}
