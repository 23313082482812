import { SpecialProductDimensionService } from './../../services/special-product-dimension-service';
import { DialogService } from 'aurelia-dialog';
import { autoinject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { ValidationController, ValidationRules, Validator } from 'aurelia-validation';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import * as moment from 'moment';
import { DepartmentService } from 'services/department-service';
import { ErrorService } from 'services/error-service';
import { NetDimensionService } from 'services/net-dimension-service';
import { NetService } from 'services/net-service';
import { RecyclingCompanyService } from 'services/recycling-company-service';
import { ServiceService } from 'services/service-service';
import { SiteService } from 'services/site-service';
import { ToastService } from 'services/toast-service';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { SendToServiceStationDialog } from './send-to-service-station-dialog';
import './service-detail-signing.scss';
import { TranslationService } from 'services/translation-service';
import { ValidationMessage } from 'models';
import { AsyncErrorHandler } from 'lib/ui';

@autoinject
export class ServiceDetailSigning {
  // todo: generate netinfo!
  private net;
  private netdimension: Models.NetDimension;
  private specialProductDimension: Models.SpecialProductDimension;
  private service: any = null;
  private departments: Array<Models.Department>;
  private sites: Array<Models.Site>;
  private departmentSites: Array<Models.Site>;
  private selectedSigningOption: number;
  private locked = false;
  private nextTabIndex: number = null;
  private deliveredDate: any;
  private canApproveService = false;
  private approvedMonths: number;
  private loadBearingsControlled = false;

  private subscription: Subscription;

  @bindable private serviceId;
  @bindable private netId;
  @bindable private isDialog: boolean = false;

  constructor(
    private errorService: ErrorService,
    private netService: NetService,
    private toastService: ToastService,
    private serviceService: ServiceService,
    private netDimensionService: NetDimensionService,
    private departmentService: DepartmentService,
    private siteService: SiteService,
    private dialogService: DialogService,
    private router: Router,
    private recyclingCompanyService: RecyclingCompanyService,
    private i18n: I18N,
    private validator: Validator,
    private validationController: ValidationController,
    private eventAggregator: EventAggregator,
    private specialProductDimensionService: SpecialProductDimensionService,
    private trservice: TranslationService
  ) {}

  private attached() {
    if (this.serviceId && this.netId) {
      this.activate({ Id: this.serviceId, NetId: this.netId });
    }
  }

  private activate(params) {
    this.getService(params.Id);
    this.getNet(params.NetId);

    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.eventAggregator.subscribe('changeTab', (tabIndex) => {
      this.nextTabIndex = tabIndex;
    });

    this.subscription = this.eventAggregator.subscribe('service-unlocked', (serviceId) => {
      if (this.service.Id === serviceId) {
        this.getService(this.service.Id);
      }
    });
  }

  deactivate() {
    if (this.subscription) {
      this.subscription.dispose();
    }
  }

  private setValidationRules() {
    ValidationRules
      // KE 01.02.2019 ignore this for now, ref #509
      //.ensure('DeliveredToDepartmentId').required().withMessage(this.i18n.tr('general.requiredField'))
      //.ensure('DeliveredToSiteId').required().withMessage(this.i18n.tr('general.requiredField'))
      .ensure('DeliveredDate')
      .required()
      .withMessage(this.i18n.tr('general.requiredField'))
      .on(this.service);
  }

  protected hasValidationMessages = false;
  protected validationMessages: Map<string, string[]> = new Map();

  private async prepareValidationMessages(validations?: ValidationMessage[]) {
    const messages = new Map<string, string[]>();
    this.validationMessages = messages;
    if (!validations || !validations.length) return;

    this.hasValidationMessages = Boolean(validations.length);

    try {
      for (const message of validations) {
        const m = this.trservice.getLocalizedValue(message, 'Message');
        const c = this.trservice.getLocalizedValue(message, 'Category');
        const existingMessage = messages.get(c);
        if (!existingMessage) {
          messages.set(c, [m]);
        } else {
          existingMessage.push(m);
        }
      }
      this.validationMessages = messages;
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  @AsyncErrorHandler
  private async getService(id: number) {
    const res = await this.serviceService.get(id);
    this.service = res;
    this.selectedSigningOption = res.SigningOptionId;
    this.setValidationRules();
    this.locked = res.Locked ? res.Locked : false;

    const canApproveRes = await this.serviceService.checkCanApproveService(this.service.Id);
    this.canApproveService = canApproveRes.CanApprove;
    this.approvedMonths = canApproveRes.ApprovedMonths;
    this.loadBearingsControlled = canApproveRes.LoadBearingsControlled;

    this.prepareValidationMessages(canApproveRes.Validations);
  }

  private getNet(id) {
    this.netService
      .getNetInfo(id)
      .then((res) => {
        this.net = res;
        this.getDimensions(res);
        this.getDepartments(res.CustomerId);
        this.getSites(res.CustomerId);
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getDimensions(net: Models.Net) {
    if (net.NetDimensionId) {
      this.netDimensionService
        .get(net.NetDimensionId + '?$expand=DimensionClass')
        .then((res) => {
          this.netdimension = res;
        })
        .catch((err) => this.errorService.handleError(err));
    } else if (net.SpecialProductDimensionId) {
      this.specialProductDimensionService
        .get(net.SpecialProductDimensionId)
        .then((res) => {
          this.specialProductDimension = res;
        })
        .catch((err) => this.errorService.handleError(err));
    }
  }

  private getDepartments(id) {
    this.departmentService
      .getAll('?$filter=CustomerId eq ' + id)
      .then((res) => {
        this.departments = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getSites(id) {
    this.siteService
      .getAll('?$filter=CustomerId eq ' + id)
      .then((res) => {
        this.sites = res;
        this.departmentSites = JSON.parse(JSON.stringify(res));
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private approveSpesicalProductService() {
    this.service.DeliveredDate = moment.default(this.service.DeliveredDate).format('YYYY-MM-DD');
    this.serviceService
      .put(this.service, this.service.Id)
      .then((updateObject) => {
        // request approval
        this.serviceService
          .approveServiceSpecialProduct(this.service.Id)
          .then((res) => {
            this.toastService.showSuccess('service.approvedAndFinished');
            this.eventAggregator.publish('serviceLocked');
            this.getService(this.service.Id);
          })
          .catch((err) => this.errorService.handleError(err));
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private approveService() {
    return this.dialogService
      .open({
        viewModel: Prompt,
        model: {
          header: 'dialog.serviceApproveAndFinishHeader',
          message: 'dialog.serviceApproveAndFinishText',
          actions: {
            delete: { enabled: false },
            save: { enabled: false },
            cancel: { enabled: true, t: 'dialog.cancel' },
            dontsave: { enabled: false },
            continue: { enabled: true, t: 'dialog.serviceApproveAndFinish' },
          },
        },
      })
      .whenClosed((response) => {
        if (response.wasCancelled) {
          return false;
        } else {
          const result = response.output;
          if (result === 'continue') {
            // update service-object
            this.service.DeliveredDate = moment.default(this.service.DeliveredDate).format('YYYY-MM-DD');
            this.serviceService
              .put(this.service, this.service.Id)
              .then((updateObject) => {
                // request approval
                this.serviceService
                  .approveService(this.service.Id)
                  .then((res) => {
                    this.toastService.showSuccess('service.approvedAndFinished');
                    this.eventAggregator.publish('serviceLocked');
                    this.getService(this.service.Id);
                  })
                  .catch((err) => this.errorService.handleError(err));
              })
              .catch((err) => this.errorService.handleError(err));
            return false;
          } else {
            return true;
          }
        }
      });
  }

  private dontApproveService() {
    let messageHeader = 'dialog.serviceDontApproveHeader';
    let messageKey =
      this.selectedSigningOption === 2 || this.selectedSigningOption === 3
        ? 'dialog.serviceDontApproveTextWithAquacom'
        : 'dialog.serviceDontApproveText';
    let continueMessage = 'dialog.serviceDontApprove';
    let newDialog = false;

    if (this.net.IsSpecialProduct) {
      messageHeader = 'dialog.serviceDontApproveSpecialProductHeader';
      messageKey = 'dialog.serviceDontApproveSpecialProductText';
      continueMessage = 'dialog.discardSpecialProduct';
    }

    if (
      !!this.net.AquacomId &&
      this.service.ServiceStatusId !== 8 &&
      this.service.ServiceStatusId !== 9 &&
      !(this.net.PlacementType == 3 && this.net.PlacementNetlogId == this.service.ServiceStationId)
    ) {
      messageKey = 'dialog.serviceDontApproveTextWithAquacomNetInSea';
      continueMessage = 'dialog.serviceOverridePlacementInAquacom';

      if (this.net.IsSpecialProduct) {
        messageKey = 'dialog.serviceDontApproveSpecialProductTextWithAquacomNetInSea';
      }

      newDialog = true;
    }

    return this.dialogService
      .open({
        viewModel: Prompt,
        model: {
          header: messageHeader,
          message: messageKey,
          actions: {
            delete: { enabled: false },
            save: { enabled: false },
            cancel: { enabled: true, t: 'dialog.cancel' },
            dontsave: { enabled: false },
            continue: { enabled: true, t: continueMessage },
          },
        },
      })
      .whenClosed((response) => {
        if (response.wasCancelled) {
          return false;
        } else {
          const result = response.output;

          if (newDialog) {
            this.dialogService
              .open({
                viewModel: SendToServiceStationDialog,
                model: {
                  service: this.service,
                  net: this.net,
                },
              })
              .whenClosed((res) => {
                if (!res.wasCancelled) {
                  this.eventAggregator.publish('serviceUpdated');
                  this.getService(this.service.Id);
                  this.getNet(this.netId);
                }
              });
          } else {
            if (result === 'continue') {
              // update service-object
              this.serviceService
                .put(this.service, this.service.Id)
                .then((updateObject) => {
                  // request approval
                  this.serviceService
                    .dontApproveService(this.service.Id, this.selectedSigningOption)
                    .then((res) => {
                      this.eventAggregator.publish('serviceLocked');
                      this.toastService.showSuccess('service.discarded');
                      this.getService(this.service.Id);
                    })
                    .catch((err) => this.errorService.handleError(err));
                })
                .catch((err) => this.errorService.handleError(err));
              return false;
            } else {
              return true;
            }
          }
        }
      });
  }

  private updateService() {
    this.serviceService
      .put(this.service, this.service.Id)
      .then((res) => {
        this.toastService.showSuccess('service.updated');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private changeDeliveredToDepartment(event) {
    this.service.DeliveredToDepartmentId = event.detail.value;

    if (this.service.DeliveredToSiteId) {
      let site = this.departmentSites.find((x) => x.Id == this.service.DeliveredToSiteId);
      if (site && site.DepartmentId == event.detail.value) {
        // we have already set the locality before selecting the department, so dont filter out
        // localities as that will clear the selection
        return;
      } else if (site && site.DepartmentId != event.detail.value) {
        this.service.DeliveredToSiteId = null;
      }
    }

    // hack to make select2 update
    this.departmentSites = [];
    setTimeout(() => {
      this.departmentSites = this.sites.filter(
        (x) =>
          x.DepartmentId &&
          this.service.DeliveredToDepartmentId &&
          x.DepartmentId.toString() == this.service.DeliveredToDepartmentId.toString()
      );
    });
  }

  changeDeliveredToSite(event) {
    this.service.DeliveredToSiteId = event.detail.value;

    let site = this.sites.find((x) => x.Id == this.service.DeliveredToSiteId);

    if (site && site.DepartmentId) {
      if (this.service.DeliveredToDepartmentId != site.DepartmentId) {
        this.service.DeliveredToDepartmentId = site.DepartmentId;
      }
    }
  }

  private validateBeforeApprove() {
    // MANUAL VALIDATION
    this.validator.validateObject(this.service).then((result) => {
      const errors = result.filter((validateResult) => {
        return !validateResult.valid;
      });
      if (errors.length > 0) {
        this.validationController.validate();
      } else {
        this.approveService();
      }
    });
  }

  async setServiceCompleteNoApproval() {
    try {
      const result = await this.validator.validateObject(this.service);
      const errors = result.filter((validateResult) => {
        return !validateResult.valid;
      });

      if (errors.length > 0) {
        this.validationController.validate();
      } else {
        this.service.DeliveredDate = moment.default(this.service.DeliveredDate).format('YYYY-MM-DD');
        await this.serviceService.put(this.service, this.service.Id);

        await this.serviceService.completeServiceWithoutApproval(this.service.Id);

        this.toastService.showSuccess('service.completedWithoutApproval');
        this.eventAggregator.publish('serviceLocked');
        this.getService(this.service.Id);
      }
    } catch (err) {
      this.errorService.handleError(err);
    }
  }

  public canDeactivate() {
    this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
    return true;
  }
}
